







































import { Component, Vue } from 'vue-property-decorator'
import PhotoManager from '../components/PhotoManager.vue'
import AlbumManager from '../components/AlbumManager.vue'

import { vxm } from '@/store/store.vuex'

@Component({ components: { PhotoManager, AlbumManager } })
export default class HomepageManager extends Vue {
  routePaths = ''
  mounted() {
    this.routePaths = this.$route.path.split('/').slice(-1)[0]

    if (this.routePaths === 'homepage' || this.routePaths === 'gallery' || this.routePaths === 'albums') {
      this.$store.dispatch('app/setSelectedPhotoManagerComponent', this.routePaths)
    }
  }
  selectComponent(component: string) {
    setTimeout(() => {
      this.$store.dispatch('app/setSelectedPhotoManagerComponent', component)
    }, 50)
    this.$store.commit('firestore/updateSelectedTitle', '')
  }

  get selectedComponent() {
    return vxm.app.selectedPhotoManagerComponent
  }

  get currentAlbum() {
    return vxm.firestore.selectedAlbumTitle
  }
}
